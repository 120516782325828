import './app.css'
import $ from 'jquery'
import LocomotiveScroll from 'locomotive-scroll';
import gsap from 'gsap'
import SplitText from "gsap/dist/SplitText";

gsap.registerPlugin(SplitText)
gsap.config({ nullTargetWarn: false })

var lastWindowWidth = 0,
	sizes = {
		width: window.innerWidth,
		height: window.innerHeight
	},
	scroll,
	Flickity,
	lazy = $('.lazy'),
	isReady = false,
	isColsFlickity = false,
	splitWords = [],
	splitLines = [],
	galleryCarousel,
	figuresCarousel,
	arcCarousel,
	vh,
	isMobile;

(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? isMobile = true : isMobile = false

$.fn.isInViewport = function() {
	var elementTop = $(this).offset().top;
	var elementBottom = elementTop + $(this).outerHeight();
	var viewportTop = $(window).scrollTop();
	var viewportBottom = viewportTop + $(window).height();
	return elementBottom > viewportTop && elementTop < viewportBottom;
}

$(window).on('load', function(){

	$.ready.then(function(){

		if (isMobile == true) {

			$('body').addClass('isMobile');

		} else {

			$('body').addClass('isDesktop');

		};

		Flickity = require('flickity');

		onWindowResize()

		window.addEventListener( 'resize', onWindowResize );
		window.addEventListener( 'orientationchange', onOrientationChange);

		globalFunction()

	})

})

function onWindowResize(e, value) {

	sizes.width = window.innerWidth
	sizes.height = window.innerHeight
	vh = sizes.height * 0.01;

	if(isMobile) {

		if(sizes.width != lastWindowWidth) {

			setH();

			$('body').addClass('progress');

		}

	} else {

		setH();
			
		$('body').addClass('progress');

	}

	clearTimeout(window.scrollUpdate);

	window.scrollUpdate = setTimeout(function(){

		if(scroll){scroll.update()};

	}, 500);

	function setH(){

		document.documentElement.style.setProperty('--vh', `${vh}px`);

	}

	clearTimeout(window.resizedFinished);

	window.resizedFinished = setTimeout(function(){

		$('body').removeClass('progress')

		if(!isMobile) { setH(); }

	}, 500);

	lastWindowWidth = sizes.width

	figuresCols();

}

function onOrientationChange(){

	clearTimeout(window.resizedFinished);

	window.resizedFinished = setTimeout(function(){

		onWindowResize();

	}, 250);

}

function appendImgs(){

	var appendBGs = $('body').find('.load_bg'),
		altBG = $('body').find('.load_alt'),
		iMGs = $('body').find('.load_img'),
		loaded = 0,
		totalAlt = altBG.length-1;

	altBG.each(function(i){

		var t = $(this),
			s = t.attr('data-src');

		t.removeAttr('data-src').css({ 'background-image': 'url('+ support_format_webp(s) +')' }).removeClass('load_alt')

		$('<img src="'+ support_format_webp(s) +'">').on('load',function(){

			if(totalAlt == loaded) {
				fire()
			}

			loaded ++
		})

	});

	iMGs.each(function(i){

		var t = $(this),
			s = t.attr('data-src');

		t.removeAttr('data-src').attr("src", support_format_webp(s)).removeClass('load_img');

		t.removeClass('load_img')

	});

	appendBGs.each(function(i){

		var t = $(this),
		s = t.attr('data-src');

		t.removeAttr('data-src').css({ 'background-image': 'url('+ support_format_webp(s) +')' }).removeClass('load_bg')

		$('<img src="'+ support_format_webp(s) +'">').on('load',function(){

			clearTimeout(window.reposition);

			window.reposition = setTimeout(function(){

				if(galleryCarousel) { galleryCarousel.reposition() }
				if(arcCarousel) { arcCarousel.reposition() }
				if(scroll){scroll.update()};

			}, 1500)

		})



	});

}

function lazyLoad(element, src){

	gsap.set(element.find('.spinner'), {autoAlpha: 1, ease: 'power3.out'});

	element.removeClass('lazy').css({ 'background-image': 'url('+ support_format_webp(src) +')' });

	$('<img src="'+ support_format_webp(src) +'">').on('load', function() {

		element.find('.spinner').addClass('pause');

		gsap.to(element.find('.spinner'), 0.5, {autoAlpha: 0, ease: 'power3.out', onComplete: function(){
			element.find('.spinner').remove()
		}})

		gsap.to(element.find('._temp'), 1, {autoAlpha: 0, ease: 'power3.out', delay: 0.5, onComplete: function(){
			element.find('._temp').remove()
		}})

	});

}

function support_format_webp(img) {

	var elem = document.createElement('canvas')

	if (!!(elem.getContext && elem.getContext('2d'))) { return img.substr(0, img.lastIndexOf(".")) + ".webp" } else { return img}

	return img
}

function fire(){

	$('body').removeAttr('style')

	let fireTL = gsap.timeline();

	fireTL

	.set('#site', {autoAlpha: 1})

	.to('#loader', 0.5, {autoAlpha: 0, onStart: function(){

		pageScroll(0)

	}})

	.call(function(){

		$('#loader').remove();

		startScroll()

		isReady = true

	})
}

function globalFunction(){

	if(lazy.length != 0) {

		lazy.each(function(){

			$(this).append('<div class="_temp full_bg" style="background-color: #000"><div class="spinner" style="visibility: hidden;"></div></div>');
		})
	}

	scroll = new LocomotiveScroll(
	{
		el: document.querySelector('[data-scroll-container]'),
		smooth: true,
		scrollFromAnywhere: true,
		tablet: {
			smooth: false,
			lerp: 0
		},
		smartphone: {
			smooth: false,
			lerp: 0
		}
	})

	scroll.on('scroll', (func, speed) => {

		if(isReady) {
			pageScroll(func);
		}

	})

	stopScroll();

	$('.scrollTo').click(function(e){

		var id =  $(this).attr('data-id'),
			target = '#' + id;

		e.preventDefault()

		scroll.scrollTo(target);

		return false;

	})


	// Gallery
	galleryCarousel = new Flickity( '.gallery_wrap', {
		prevNextButtons: false,
		accessibility: true,
		pageDots: false,
		percentPosition: true,
		freeScroll: true,
		contain: true,
	});

	var flkty = Flickity.data('.gallery_wrap');
	var $imgs = $('.gallery_col span');

	galleryCarousel.on( 'scroll', function( event, progress ) {

		$('.gallery_nav h6').removeClass('active')

		flkty.slides.forEach( function( slide, i ) {
			var img = $imgs[i];
			var x = ( slide.target + flkty.x ) * -1/8;
			var id = $(slide.parent.selectedCell.element).attr('data-id')
			img.style.transform = 'translateX( ' + x  + 'px)';
			$('#' + id).addClass('active')
			
		});

		$('.gallery_nav h6').click(function(){

			var id = $(this).attr('id'),
				index = $('.gallery_col[data-id='+id+']').index()

			galleryCarousel.select( index )

		})

	});

	// Carousel
	arcCarousel = new Flickity( '.arc_slides', {
		prevNextButtons: false,
		accessibility: true,
		pageDots: false,
		percentPosition: true,
		selectedAttraction: 0.2,
		friction: 0.8,
		contain: true,
		cellAlign: 'left'
	});

	$('.caur_arrows .rounded_arrow').on( 'click', function() {
		$(this).hasClass('next') ? arcCarousel.next(true) : arcCarousel.previous(true)
	});

	appendImgs()

}

function stopScroll(){

	$('body').addClass('hidden')

	if(scroll) { scroll.stop() }

}

function startScroll(){

	$('body').removeClass('hidden')

	if(scroll) { scroll.start() }

}

function figuresCols() {

	if(sizes.width <= 500){

		if ( isColsFlickity == false ) {

			build();

		};

	} else {

		if ( isColsFlickity == true ) {

			isColsFlickity = false;

			figuresCarousel.destroy();

			figuresCarousel = null

		}

	}

	function build(){

		isColsFlickity = true;

		figuresCarousel = new Flickity('.cicles_wrap', {
			prevNextButtons: false,
			accessibility: true,
			pageDots: false,
			percentPosition: true,
			selectedAttraction: 0.2,
			friction: 0.8,
			contain: true,
			cellAlign: 'left'
		})
	}

}

function pageScroll(val){

	let eleWrap = $('._eleWrap');

	if( eleWrap.length != 0 ) {

		eleWrap.each(function(i){

			let $this = $(this),
				eleY = $this.find('._eleY'),
				eleX = $this.find('._eleX');

			if($this.isInViewport()) {
				animateEle($this, eleY, eleX)
			}

		})

	}

	let splitWrap = $('._splitWrap');

	if( splitWrap.length != 0 ) {

		splitWrap.each(function(i){

			let $this = $(this),
				getWords = $this.find('._splitWords'),
				getLines = $this.find('._splitLines');

			if($this.isInViewport()) {
				split($this, getWords, getLines, i)
			}

		})

	}

	if(lazy.length != 0) {

		lazy.each(function(){

			var $this = $(this),
				src = $this.attr('data-src');

			if(!$this.hasClass('inviewport')) {

				if($this.isInViewport() && !$this.hasClass('inview')) {

					$this.addClass('inview');

					lazyLoad($this, src)
				}

			} else {

				if(!$this.hasClass('inview')) {

					$this.addClass('inview');

					lazyLoad($this, src)

				}

			}

		})

	}

	if($('.cicles_wrap').isInViewport() && !$('.cicles_wrap').hasClass('inview') ) {

		$('.cicles_wrap').addClass('inview');

		gsap.set($('.cicles_wrap'), {autoAlpha: 1}, 0)

		gsap.from('.circle', 0.5, { y: 100, autoAlpha: 0, ease: 'power3.out', stagger: 0.1 })

	}

	let counters = $('.count');

	if(counters.length != 0) {

		counters.each(function () {

			let $this = $(this);

			if($this.isInViewport() && !$this.hasClass('inview') ) {

				$this.addClass('inview');

				gsap.set($this, {autoAlpha: 1})

				gsap.from($this, {
					textContent: 0,
					duration: 2,
					ease: "power3.out",
					snap: { textContent: 1 },
					stagger: {
					each: 1.0,
						onUpdate: function() {
							this.targets()[0].innerHTML = numberWithCommas(Math.ceil(this.targets()[0].textContent));
						},
					}
				});

			}

		});

	}

}

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function split($this, getWords, getLines, i) {

	if(!$this.hasClass('inview') ) {

		$this.addClass('inview');

		if(getWords.length != 0) {
			splitWords[i] = new SplitText(getWords, {type:"words", wordsClass:"SplitClass"});
			gsap.set(getWords, {autoAlpha: 1})
			gsap.set(splitWords[i].words, { y: 20, autoAlpha: 0})
			gsap.to(splitWords[i].words, 0.5, { y: 0, autoAlpha: 1, ease: 'power3.out', stagger: 0.1, onComplete: function(){
				splitWords[i].revert()
			} })
		}

		if(getLines.length != 0) {
			gsap.set(getLines, {autoAlpha: 1, delay: 0.5})
			splitLines[i] = new SplitText(getLines, {type:"lines", linesClass:"SplitClass"});
			gsap.set(splitLines[i].lines, { y: 20, autoAlpha: 0})
			gsap.to(splitLines[i].lines, 0.5, { y: 0, autoAlpha: 1, ease: 'power3.out', delay: 0.5, stagger: 0.1, onComplete: function(){
				splitLines[i].revert()
			} })
		}

	}
}

function animateEle($this, eleY, eleX) {

	if(!$this.hasClass('inview') ) {

		$this.addClass('inview');

		gsap.set($this, {autoAlpha: 1}, 0)

		if(eleY.length != 0) {
			gsap.set(eleY, { y: 100, autoAlpha: 0})
			gsap.to(eleY, 1, { y: 0, autoAlpha: 1, ease: 'power3.out', delay: 0.4, stagger: 0.15 })
		}

		if(eleX.length != 0) {

			gsap.set(eleX, { x: 100, autoAlpha: 0})
			gsap.to(eleX, 1, { x: 0, autoAlpha: 1, ease: 'power3.out', delay: 0.4, stagger: 0.15 })
		}
	}

}
